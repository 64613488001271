/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file KeyboardShortcutText component
 * @author Colin Walters and Liam Liden
 * @module Epic.VideoApp.Components.Utilities.KeyboardShortcutText
 */

import React, { FC } from "react";
import { isMobile } from "~/utils/os";
import style from "./KeyboardShortcutText.module.scss";

export enum KeyboardShortCutTextTestIds {
	self = "KeyboardShortcutText",
}

/**
 * Props for KeyboardShortcutText Component
 */
interface IProps {
	text: string;
	keyCombination?: string[];
	className?: string;
}

// modifier keys to ignore when finding the key to underline
const modifierKeys = ["alt", "control", "shift"];

/**
 * The KeyboardShortcutText component
 * @param props The props ;)
 */
const KeyboardShortcutText: FC<IProps> = (props) => {
	const { keyCombination, text, className } = props;

	if (!keyCombination) {
		return <span className={className}>{text}</span>;
	}

	// use the first non-modifier key as the letter to underline
	const underlineKey = keyCombination
		.find((key) => !modifierKeys.includes(key.toLowerCase()))
		?.toLowerCase();

	// if that character isn't included for some reason (translation?) just return the text
	const textLower = text.toLowerCase();
	if (isMobile() || !underlineKey || !textLower.includes(underlineKey)) {
		return <span className={className}>{text}</span>;
	}

	// if there's a full word starting with the hotkey, use that otherwise underline first occurrence
	const fullWord = textLower.split(" ").find((word) => word.startsWith(underlineKey));
	const keyIndex = textLower.indexOf(fullWord || underlineKey);
	const beforeKey = text.slice(0, keyIndex);
	const afterKey = text.slice(keyIndex + 1);

	return (
		<span className={className} data-testid={KeyboardShortCutTextTestIds.self}>
			{beforeKey}
			<span className={style["underline"]}>{text[keyIndex]}</span>
			{afterKey}
		</span>
	);
};

KeyboardShortcutText.displayName = "KeyboardShortcutText";

export default KeyboardShortcutText;
