/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file Menu component for menus in the Lobby Hardware test (NOT the in-call drop-down menu)
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareTest.HardwareTestMenu
 */

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { hardwareTestActions } from "~/state";
import { HardwareTestTab } from "~/types";
import { DefaultImageNames } from "~/types/backgrounds";
import { MenuContextProvider } from "../Utilities/MenuContext";
import styles from "./HardwareTestMenu.module.scss";

/**
 * Props for HardwareTestMenu Component
 */
interface IProps {
	tabType: HardwareTestTab;
	visible: boolean;
	needsAutoFocus: boolean;
	id: string;
	label: string;
}

export enum HardwareTestMenuTestIds {
	devicesMenu = "HardwareTestDevicesMenu",
	backgroundMenu = "HardwareTestBackgroundMenu",
}

/**
 * The HardwareTestMenu component
 * @param props The props ;)
 */
const HardwareTestMenu: FC<IProps> = (props) => {
	const { tabType, visible, needsAutoFocus, id, children, label } = props;

	const dispatch = useDispatch();

	const handleKeyPress = useCallback<React.KeyboardEventHandler<HTMLDivElement>>(
		(event) => {
			switch (event.key) {
				case "Esc":
				case "Escape":
					dispatch(hardwareTestActions.closeTabAndFocusTabButton(tabType));
					break;
				default:
					break;
			}
		},
		[dispatch, tabType],
	);

	const menuRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (needsAutoFocus && visible) {
			menuRef.current?.focus({ preventScroll: true });
			menuRef.current?.scrollIntoView({ behavior: "smooth" });
		}
	}, [needsAutoFocus, visible]);

	if (tabType === HardwareTestTab.devices) {
		return (
			<div
				tabIndex={0}
				aria-hidden={!visible}
				className={!visible ? styles["hidden"] : styles["hardwareMenu"]}
				role="menu"
				ref={menuRef}
				aria-label={label}
				id={id}
				data-testid={HardwareTestMenuTestIds.devicesMenu}
			>
				<MenuContextProvider isInMenu>{children}</MenuContextProvider>
			</div>
		);
	} else {
		return (
			<div
				tabIndex={0}
				aria-hidden={!visible}
				className={!visible ? styles["hidden"] : styles["hardwareMenu"]}
				role="menu"
				onKeyDown={handleKeyPress}
				ref={menuRef}
				aria-activedescendant={DefaultImageNames.none}
				aria-label={label}
				id={id}
				data-testid={HardwareTestMenuTestIds.backgroundMenu}
			>
				<MenuContextProvider isInMenu>{children}</MenuContextProvider>
			</div>
		);
	}
};

HardwareTestMenu.displayName = "HardwareTestMenu";

export default HardwareTestMenu;
