/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Button component with icon and keyboard shortcut
 * @author Spencer Eanes
 * @module Epic.VideoApp.Components.Utilities.BaseButton
 */

import React, { ComponentType, FC, ReactElement, RefObject, useRef } from "react";
import { ITestable } from "~/types";
import { resolveClassName } from "~/utils/className";
import { noOp } from "~/utils/general";
import { IconProps } from "../../icons";
import styles from "./BaseButton.module.scss";
import KeyboardShortcut from "./KeyboardShortcut";
import KeyboardShortcutText from "./KeyboardShortcutText";
import Tooltip, { ITooltipInfo } from "./Tooltip";

export enum BaseButtonTestIds {
	self = "BaseButton",
}

/**
 * Core props for BaseButton Component
 */
export interface IButtonProps extends ITestable {
	icon?: ComponentType<IconProps>;
	onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
	keyboardShortcut?: string[];
	text?: string;
	buttonRef?: RefObject<HTMLButtonElement>;
	forImagePreviewPane?: boolean;
	tooltipInfo?: ITooltipInfo;
	id?: string;
	// Will disable functionality of the button but keep it present in the tab order and read it as 'unavailable'
	disabledTabbable?: boolean;
	title?: string;
	isLTR?: boolean;
}

/**
 * Full set of base button props that includes various values including props to overrides styles
 */
export interface IBaseButtonProps extends IButtonProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
	labelClassName?: string;
	rawClass?: string;
	noStyle?: boolean;
	iconClassName?: string;
	onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * The BaseButton component
 *
 * A note on focus: The focus behavior for this element is different between tabbing and clicking!
 *  - When the button is focused by tabbing, focus will be applied to the <button ... /> element (that's
 *    in the tab order).
 *  - When focus is applied by clicking, focus *will not* go to the button, but instead will go to the
 *    button's child <div ... /> element which has tabindex=-1
 *
 * The difference in focus behavior makes it so that the tooltip is shown when the button is focused via
 * keyboard navigation, but not focused on click.
 * @param props The props
 */
const BaseButton: FC<IBaseButtonProps> = (props): ReactElement | null => {
	const {
		icon: Icon,
		onClick,
		keyboardShortcut,
		forImagePreviewPane,
		text,
		rawClass,
		buttonRef,
		tooltipInfo,
		children,
		labelClassName,
		noStyle = false,
		iconClassName,
		disabledTabbable,
		isLTR,
		...buttonProps
	} = props;

	const buttonClass = resolveClassName(
		styles,
		{
			baseButton: !noStyle,
			toolTipButton: true,
			disabled: buttonProps.disabled || buttonProps["aria-disabled"] === "true",
		},
		rawClass,
	);

	const button = useRef<HTMLButtonElement>(null);
	const refToButton = buttonRef ?? button;
	const appliedLabelClassName = labelClassName || styles["labelText"];

	return (
		<button
			onClick={!disabledTabbable ? onClick : undefined}
			type="button"
			className={buttonClass}
			ref={refToButton}
			onTouchStart={noOp}
			aria-disabled={disabledTabbable}
			dir={isLTR ? "ltr" : undefined}
			data-testid={BaseButtonTestIds.self}
			{...buttonProps}
		>
			<div tabIndex={-1}>
				<div className={styles["iconAndLabelWrapper"]}>
					{Icon && <Icon height={30} width={30} aria-hidden className={iconClassName} />}
					{keyboardShortcut && !buttonProps.disabled && !disabledTabbable ? (
						<KeyboardShortcut
							keyCombination={keyboardShortcut}
							callback={onClick}
							className={appliedLabelClassName}
							text={text}
							forImagePreviewPane={forImagePreviewPane}
						/>
					) : (
						text && (
							<KeyboardShortcutText
								text={text}
								keyCombination={keyboardShortcut}
								className={appliedLabelClassName}
							/>
						)
					)}
				</div>
				{children}
				{tooltipInfo && <Tooltip elementWithTooltip={refToButton.current} {...tooltipInfo} />}
			</div>
		</button>
	);
};

BaseButton.displayName = "BaseButton";

export default BaseButton;
